import React from 'react'

import Layout from '~components/Layout'
import WorksTemplate from '~templates/Works';

export default function IndexPage() {
  return (
    <Layout>
      <WorksTemplate />
    </Layout>
  )
}
