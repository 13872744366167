import React, { FC, ReactElement } from 'react'

import { root } from './Works.module.scss'
import Section from '~components/Section';
import { graphql, useStaticQuery } from 'gatsby';
import WorksSection from '~templates/Works/components/Works';

const WorksTemplate: FC<unknown> = (): ReactElement => {
  const data = useStaticQuery(query);
  const sections = data.allContentfulSection?.nodes;

  return (
    <div className={root}>
      {sections.sort((a: any, b: any) => a.order - b.order).map((section: any, index: number) => {
        if (!section.content[0].id) return;
        return (
          <Section key={index} title={section.title} footerTitle={section.link}>
            <div>
              {section.content.map((content: any, index: number) => (
                <WorksSection key={`works${index}`} content={content} />
              ))}
            </div>
          </Section>
        )
      })}
    </div>
  )
}

export default WorksTemplate


export const query = graphql`
query allContentfulWorksSectionsQuery {
  allContentfulSection {
    nodes {
      content {
        ... on ContentfulWorks {
          id
          title {
            raw
          }
          categories {
            title
          }
          works {
            order
            title
            slug
            desc {
              desc
            }
            category {
              title
            }
            img {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 98
                placeholder: TRACED_SVG
              )
            }
          }
        }
      }
    }
  }
}
`
